@media only screen and (min-width: 768px) {
    .dataTables_length{
        margin-bottom: 1%;
        position: relative;
        float: left;
    }
    .dataTables_paginate{
        margin-top: 1% !important;
        position: relative;
        float: right;
    }
    .dataTables_info{
        position: relative;
        float: left;
    }
}

.dataTables_info{
    margin-bottom: 5%;
}

#dt_table_filter input{
    border: 1px solid #cccccc;
    padding: 0.5em;
    border-radius: 5px;
    font-size: 0.9em;
}

.dataTables_length select{
    border: 1px solid #cccccc;
    padding: 0.3em;
    border-radius: 5px;
    color: #525f7f
}

.previous{
    border-radius: 5px 0px 0px 5px;
}
.next{
    border-radius: 0px 5px 5px 0px;
}

.paginate_button{
    border: 1px solid #cccccc;
    padding: 0.5em;
    cursor: pointer;
    color: #525f7f;
}

.paginate_button:hover{
    color: #282e3e;
}

.dataTables_paginate span .paginate_button{
    padding: 0.5em 0.7em !important;
}

table .collapse.in {
	display: table-row;
}

.ellipsis{
    padding: 0.5em 0.7em !important;
    border: 1px solid #cccccc;
    padding: 0.5em;
    cursor: pointer;
    color: #525f7f;
}

.ellipsis:hover{
    color: #282e3e;
}