.linha{
    width:100px;
    height: 10px;
    background-color:#dc5835;
}

.cabecalho{
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #007bff;
    text-transform: uppercase;
}


.fit-content{
    width: fit-content;
}

.bg-roxo{
    background-color: #460439;
    color: #fff;
}

input[type=radio] {
    width: 18px;
    height: 18px;
}

.font-size-18{
    font-size: 18px;
}

.input-group-text {
    background-color: #fff;
    border-right: 0px;
}

#contrato input[type="date"]{
    border-left: 0px;
}

#imovel select{
    border-left: 0px;
}

#aluguel input{
    border-left: 0px;
}

#aluguel select{
    border-left: 0px;
}

.headTableCamp{
    border: 1px solid #e6e6e6;
    height: 2rem;
    border-radius: 4px;
}

.dataTables_wrapper{
    overflow: auto;
}

#modal-max-800{
    max-width: 900px;
}