.itemWizard{
    color: white;
    cursor: pointer;
    min-width: 20%;
}

.activeWizard:hover{
    color: black !important;
    background-color: white !important;
}

.activeWizard{
    color: black !important;
    background-color: white !important;
}

.borderConteudo{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.desabilitar{
    background-color: white !important;
}