/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Bootstrap (4.1.3) components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";


// React differences
@import "react/react-differences";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body{
    -webkit-print-color-adjust: exact;
}

.colorBackground{
    background-image: linear-gradient(270deg, #ee585a 0%, #3b1e3e 100%);
}

.colorButton {
    background-color:  #ee585a;
    color: white;
}

.colorButton:hover{
    background-color:  #ee585a;
    color: white;
}

.colorEconsult {
    background-color: #11a1ef ;
    color: white;
}

.colorEconsult:hover{
    background-color:  #11a1ef;
    color: white;
}

.colorButtonSecundario {
    background-color:  #3b1e3e;
    color: white;
}

.colorButtonSecundario:hover{
    background-color:  #3b1e3e;
    color: white;
}
.exibirCelularLine{
    display: none;
}

.ocultarInformacoes{
    display: none;
}

.tamanhoDownloadContrato{
    width: 65%;
}

.quebrarLinha{
    word-break: break-all;
}

.tooltipnew {
    position: relative;
    float: right;
}

.tooltipnew .tooltiptext {
    visibility: hidden;
    width: 240px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    bottom: 100%;
    left: -41.5px;
    margin-left: -60px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltipnew .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltipnew:hover .tooltiptext {
    visibility: visible;
}

.carta-fundo{
    width: 100%;
    min-width: 88%;
    // min-height: 670px;
    background: rgb(103,55,120);
    background: linear-gradient(180deg, rgba(103,55,120,1) 0%, rgba(255,90,91,1) 100%);
    padding: 20px;
}

.carta{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('../img/marca.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    font-family: 'Poppins', sans-serif;
}

.carta p{
    margin: 0;
}

.carta .header{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 510px){
    .carta .header{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .carta .header img{
        margin: 10px 0px;
    }
}

.carta .header .info{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carta .header .info p:nth-child(1){
    height: 25px;
    font-weight: 600;
    color: #222;
}

.carta .header .info p:nth-child(2){
    font-size: 14px;
    color: #999;
}

.carta .body{
    width: 100%;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.carta .body .garantia{
    margin-bottom: 20px;
}

.carta .body .garantia h1{
    font-size: 20px;
    color: #673778;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.carta .body .garantia p{
    font-size: 12px;
    color: #626262;
    text-align: center;
}

.carta .body .garantia-info p{
    text-align: center;
}

.carta .body .garantia-info p:nth-child(1){
    margin-top: 10px;
}

.carta .body .garantia-info p:nth-child(1), .carta .body .garantia-info p:nth-child(3){
    font-size: 14px;
    color: #555;
    font-weight: 500;
    text-transform: uppercase;
}

.carta .body .garantia-info p:nth-child(2){
    font-size: 30px;
    color: #222;
    font-weight: 600;
    text-transform: capitalize;
}

.carta .body .garantia-info p:nth-child(4){
    font-size: 26px;
    color: #FF5A5B;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 10px;
}

.carta .body .garantia-info p:nth-child(5){
    font-size: 12px;
    color: #673778;
    font-weight: 500;
}

.carta .body .textos{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carta .body .textos p:nth-child(1){
    color: #222;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
}

.carta .body .textos p:nth-child(2){
    width: 90%;
    color: #555;
    font-size: 12px;
    margin-bottom: 10px;
}

.carta .footerCarta{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carta .footerCarta p:nth-child(1){
    font-size: 14px;
    color: #673778;
    font-weight: 600;
}

.carta .footerCarta p:nth-child(2){
    font-size: 14px;
    color: #626262;
}

@media only screen and (max-width: 600px) {
    .ocultarDivSeparador{
        display: none;
    }
    .ocultarTextLine{
        display: none;
    }
    .exibirCelularLine{
        display: block;
    }
    .tamanhoDownloadContrato{
        width: 100%;
    }
}

@media print {
    button {
      visibility: hidden;
    }
  }
