.itemConsulta{
    font-weight: bold !important;
    font-size: 1.1em !important;
    cursor: pointer;
    background-color: white !important;
}

.activeOpcao:hover{
    background-color:  #1a1a1a !important;
}

.activeOpcao{
    color: white !important;
    background-color:  #333333 !important;
}

.navPrincipal{
    width: 40px;
    cursor: pointer;
    color: white !important;
    background-color: #808080;
    border-radius: 5px;
}

.activeOpcaoPrincipal:hover{
    background-color: #1a1a1a !important;
    border-radius: 5px;
}

.activeOpcaoPrincipal{
    width: 40px;
    color: white !important;
    background-color:  #333333 !important;
    border-radius: 5px;
}

.progress{
    width: 350px;
    margin-top: 4.6vh;
}

#carta{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}

.rebocoDeCarta{
    background: white;
}

@media only screen and (max-width: 600px) {
    .progress{
        width: 180px;
        margin-top: 4.7vh;
    }
}

